<template>
  <div class="params">
    <a-tabs v-model="active" @change="tabsChange">
      <!-- <a-tab-pane key="1" tab="报表设置"> </a-tab-pane> -->
      <a-tab-pane key="2" tab="报告设置"> </a-tab-pane>
      <a-tab-pane key="3" tab="科目设置"> </a-tab-pane>
      <a-tab-pane key="4" tab="安全设置"> </a-tab-pane>
    </a-tabs>
    <sheetSetting v-if="active == '1'" />
    <reportSetting v-if="active == '2'" />
    <subjectSetting v-if="active == '3'" />
    <safeSetting v-if="active == '4'" />
  </div>
</template>

<script>
import sheetSetting from "../components/sheetSetting.vue";
import reportSetting from "../components/reportSetting.vue";
import subjectSetting from "../components/subjectSetting.vue";
import safeSetting from "../components/safeSetting.vue";
export default {
  name: "Params",
  components: {
    sheetSetting,
    reportSetting,
    subjectSetting,
    safeSetting,
  },
  data() {
    return {
      active: "2",
      keys: Math.random(),
    };
  },
  created() {},
  methods: {
    tabsChange() {
      this.keys = Math.random();
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .ant-modal-body {
  line-height: 1 !important;
}
</style>
