<template>
  <div v-loading="loading" class="safeSetting">
    <div class="title">
      登录时是否强制要求修改密码
      <a-tooltip>
        <template slot="title">
          如果开启后，用户首次登录时强制弹出修改密码的对话框，密码是6位数且不为123456即可，任意数字、字母、字符都可以，不限制。
        </template>
        <a-icon type="question-circle" style="color: #2474ed" theme="filled" />
      </a-tooltip>
    </div>
    <div class="radio">
      <a-radio-group v-model="isforce" name="radioGroup">
        <a-radio :value="1"> 是 </a-radio>
        <a-radio :value="0"> 否 </a-radio>
      </a-radio-group>
    </div>
    <a-button type="primary" @click="submit">应用</a-button>
  </div>
</template>

<script>
import { Tkforce, setTkforce } from "@/core/api/newOther/union";
import { getStore } from "@/core/util/store";
export default {
  name: "SafeSetting",
  data() {
    return {
      loading: false,
      isforce: 0,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      try {
        this.loading = true;
        const res = await Tkforce({
          schoolUnionId: getStore({ name: "school_id" }),
        });
        this.isforce = res.data.data;
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },
    async submit() {
      this.loading = true;
      try {
        await setTkforce({
          schoolUnionId: getStore({ name: "school_id" }),
          isforce: this.isforce,
        });
        this.$message({
          type: "success",
          message: "提交成功",
          showClose: true,
        });
        this.getData();
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.safeSetting {
  .title {
    height: 36px;
    background: #f3f8ff;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 0 8px;
    line-height: 36px;
    margin-bottom: 18px;
    font-size: 14px;
    color: #0a1119;
  }
  .radio {
    margin-bottom: 18px;
  }
}
</style>
