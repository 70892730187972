import axios from "@/core/services/axios";
// 联考用户管理分页查询
export const getUserList = (params) =>
  axios({ url: "/cms/schoolunionuser/page", method: "GET", params: params });
// 添加联考用户管理
export const addUser = (params) =>
  axios({ url: "/cms/schoolunionuser/add", method: "post", data: params });
// 批量删除联考用户
export const deleteUser = (params) =>
  axios({
    url: "/cms/schoolunionuser/delete/batch",
    method: "post",
    data: params,
  });
// 获取分类
export const categoryList = () =>
  axios({
    url: `/cms/schoolunionuser/type`,
    method: "GET",
  });
// 修改联考用户管理
export const editUser = (params) =>
  axios({
    url: "/cms/schoolunionuser/edit",
    method: "put",
    data: params,
  });
// 清空联考用户
export const clearAllUser = (params) =>
  axios({
    url: `/cms/schoolunionuser/clear`,
    method: "post",
    data: params,
  });
// 通过主管单位id 查询 管辖的学校
export const getSchoolNotPageUnionById = (params) =>
  axios({
    url: `/cms/school/getSchoolIds/super/foruserV2`,
    method: "get",
    params: params,
  });
// 通过主管单位id 查询 管辖的学校（分页）
export const getSchoolUnionById = (params) =>
  axios({
    url: `/cms/school/getSchoolIds/super/foruser`,
    method: "get",
    params: params,
  });
// 通过主管单位id 查询 管辖学校
export const getSchoolIdByUnionId = (params) =>
  axios({
    url: `/cms/school/getSchoolIds/super`,
    method: "get",
    params: params,
  });
// 联考权限管理分页查询
export const permissionList = (params) =>
  axios({
    url: `/cms/userpermission/page`,
    method: "GET",
    params: params,
  });
// 联考权限管理添加
export const addPermission = (params) =>
  axios({
    url: `/cms/userpermission/add`,
    method: "post",
    data: params,
  });
// 导入联考用户
export const batchImportSchoolUser = ({ schoolId, file }) => {
  return axios({
    url: `/cms/schoolunionuser/import/add?schoolId=${schoolId}`,
    method: "post",
    data: file,
  });
};
// 批量修改联考用户
export const batchEditSchoolUser = (params) => {
  return axios({
    url: `/cms/schoolunionuser/import/update`,
    method: "post",
    data: params,
  });
};
// 批量修改联考用户
export const exportSchoolUser = (params) => {
  return axios({
    url: `/cms/schoolunionuser/export`,
    method: "post",
    data: params,
  });
};

// 联考权限管理修改
export const editAuthority = (params) =>
  axios({
    url: `/cms/userpermission/update`,
    method: "POST",
    data: params,
  });
// 联考权限批量删除
export const delAuthority = (params) =>
  axios({
    url: `/cms/userpermission/batch/del`,
    method: "POST",
    data: params,
  });

// 权限清空用户
export const cleanUser = (params) =>
  axios({
    url: `/cms/userpermission/clean/user`,
    method: "POST",
    data: params,
  });
// 扫描点管理 分页
export const scanpointPage = (params) =>
  axios({
    url: `/cms/scanpoint/page`,
    method: "get",
    params: params,
  });
// 添加扫描点 编辑也用这个 多穿个id
export const addScanPoint = (params) =>
  axios({
    url: `/cms/scanpoint/save`,
    method: "post",
    data: params,
  });
// 删除扫描点

export const delScanPoint = (params) =>
  axios({
    url: `/cms/scanpoint/delete/batch`,
    method: "post",
    data: params,
  });
// 扫描点学校列表
export const scanPointSchoolList = (params) =>
  axios({
    url: `/cms/scanpoint/save/school`,
    method: "get",
    params: params,
  });
// 扫描点用户列表
export const scanPointUserList = (params) =>
  axios({
    url: `/cms/scanpoint/save/user`,
    method: "get",
    params: params,
  });

// 导出扫描点用户列表
export const importScanPoint = (params) =>
  axios({
    url: `/cms/scanpoint/import`,
    method: "post",
    data: params,
  });
// 导出扫描点用户列表
export const exportScanPoint = (params) =>
  axios({
    url: `/cms/scanpoint/export`,
    method: "get",
    params: params,
  });
// 获取考试名称字典
export const getExam = (params) =>
  axios({
    url: `/cms/schoollog/getExam`,
    method: "get",
    params: params,
  });
// 获取用户名称字典
export const getUser = (params) =>
  axios({
    url: `/cms/schoollog/getUser`,
    method: "get",
    params: params,
  });
// 联考日志分页查询
export const getLog = (params) =>
  axios({
    url: `/cms/schoollog/page`,
    method: "get",
    params: params,
  });
// 导出考试管理或成绩
export const exportLog = (params) =>
  axios({
    url: `/cms/schoollog/export`,
    method: "post",
    data: params,
  });
// 查询报表设置
export const getformSetting = (params) =>
  axios({
    url: `/cms/schoolunionparam/form/query`,
    method: "get",
    params: params,
  });
// 保存报表设置
export const saveFormSetting = (params) =>
  axios({
    url: `/cms/schoolunionparam/form/save`,
    method: "post",
    data: params,
  });
// 查询报告设置
export const getReportSetting = (params) =>
  axios({
    url: `/cms/schoolunionparam/report/query`,
    method: "get",
    params: params,
  });
// 保存报告设置
export const saveReportSetting = (params) =>
  axios({
    url: `/cms/schoolunionparam/report/save`,
    method: "post",
    data: params,
  });

// 查询所有学科
export const getAllSubject = (params) =>
  axios({
    url: `/cms/school/all/subject`,
    method: "get",
    params: params,
  });

// 查询科目设置
export const getSubjectSetting = (params) =>
  axios({
    url: `/cms/schoolunionparam/subject/query`,
    method: "get",
    params: params,
  });
// 保存科目设置
export const saveSubjectSetting = (params) =>
  axios({
    url: `/cms/schoolunionparam/subject/save`,
    method: "post",
    data: params,
  });
// 联考获取是否强制修改密码
export const Tkforce = (params) =>
  axios({
    url: `/cms/school/tk/force`,
    method: "get",
    params: params,
  });
// 联考保存是否强制修改密码
export const setTkforce = (params) =>
  axios({
    url: `/cms/school/tk/force`,
    method: "post",
    data: params,
  });
// 联考学校分页管理
export const getSchoolPage = (params) =>
  axios({
    url: `/cms/schoolmanmge/page`,
    method: "get",
    data: params,
  });
// 根据省市区获取学校
export const getSchoolListByarea = (params) =>
  axios({
    url: `/cms/school/getschoollistbygeoandkey`,
    method: "post",
    data: params,
  });
// 修改主管单位下级学校关联数据
export const editSchoolUnderUnion = (params) =>
  axios({
    url: `/cms/school/edit/subschool`,
    method: "post",
    data: params,
  });

// 修改下级学校信息
export const editSubschool = (params) =>
  axios({
    url: `/cms/school/update/subschool`,
    method: "post",
    data: params,
  });
// 删除主管单位下级学校
export const delSubSchool = (params) =>
  axios({
    url: `/cms/school/delete/subschool`,
    method: "post",
    data: params,
  });
// 导入主管单位下级学校
export const importSubschool = (params) =>
  axios({
    url: `/cms/school/import/subschool`,
    method: "post",
    data: params,
  });

// 导出主管单位下级学校列表
export const exportSubSchool = (params) =>
  axios({
    url: `/cms/school/export/subschool`,
    method: "get",
    params: params,
  });
