<template>
  <div class="reportSetting">
    <a-radio-group v-model="mode">
      <a-radio-button value="1"> 发布到学校 </a-radio-button>
      <a-radio-button value="2"> 发布到学生 </a-radio-button>
    </a-radio-group>
    <div class="filter">
      适用学段学年：
      <div class="filter-item">
        学段：
        <a-select
          v-model="search.level"
          style="width: 120px"
          not-found-content="暂无数据"
          placeholder="全部学段"
          @change="filterChange"
        >
          <a-select-option
            v-for="(item, index) in levelOptions"
            :key="index"
            :value="item.value"
            >{{ item.label }}</a-select-option
          >
        </a-select>
      </div>
      <div class="filter-item">
        学年：
        <a-select
          v-model="search.year"
          style="width: 120px"
          not-found-content="暂无数据"
          placeholder="全部学年"
          @change="filterChange"
        >
          <a-select-option
            v-for="(item, index) in yearList"
            :key="index"
            :value="item.value"
            >{{ item.label }}</a-select-option
          >
        </a-select>
      </div>
    </div>
    <div class="tag">
      默认参数设置
      <a-tooltip placement="top">
        <template slot="title">
          <span>在统计参数可针对每场考试进行个性化设置</span>
        </template>
        <a-icon type="exclamation-circle" theme="filled" />
      </a-tooltip>
    </div>
    <template v-if="mode === '1'">
      <div class="title">学生排名</div>

      <!-- 发布到学校 可以显示排名 -- 2024/01/17 磊哥 -->
      <div class="radio">
        <a-radio-group v-model="params.publishSchoolIsDisplayStudentRank">
          <a-radio :value="1"> 显示 </a-radio>
          <a-radio :value="0">
            不显示
            <a-tooltip>
              <template slot="title">
                成绩数据发布到学校后，各类校级报告均不显示联考、校内、班级排名相关字段的信息数据
              </template>
              <a-icon
                type="question-circle"
                style="color: #2474ed"
                theme="filled"
              />
            </a-tooltip>
          </a-radio>
        </a-radio-group>
      </div>
      <div class="sub-title">成绩排序方式：</div>
      <div class="radio">
        <!-- 发布到学校 可以显示排名 -- 2024/01/17 磊哥 -->
        <a-radio-group v-model="params.publishSchoolStudentRankListOrder">
          <a-radio :value="1"> 成绩降序排序 </a-radio>
          <a-radio :value="2"> 考号升序排序 </a-radio>
          <a-radio :value="4"> 随机排序 </a-radio>
          <a-radio :value="3"> 姓名拼音首字母排序 </a-radio>
        </a-radio-group>
      </div>
      <div class="title">是否显示区域均分</div>
      <div class="radio">
        <a-radio-group v-model="params.publishSchoolIsDisplayAreaAverageScore">
          <a-radio :value="1"> 显示 </a-radio>
          <a-radio :value="0">
            不显示
            <a-tooltip>
              <template slot="title">
                校端考试报告中的“联考报告”不显示区域均分，校级其他所有报告中不显示区域均分，学生WEB端报告也不显示整体均分
              </template>
              <a-icon
                type="question-circle"
                style="color: #2474ed"
                theme="filled"
              />
            </a-tooltip>
          </a-radio>
        </a-radio-group>
      </div>
      <div class="title">是否显示学校排名</div>
      <div class="radio">
        <a-radio-group v-model="params.publishSchoolIsDisplaySchoolRank">
          <a-radio :value="1"> 显示 </a-radio>
          <a-radio :value="0">
            不显示
            <a-tooltip>
              <template slot="title">
                学校端考试报告中的“联考报告”不显示任何排名字段的数据，校级报告中考情概述总分及各学科数据不显示排名的内容
              </template>
              <a-icon
                type="question-circle"
                style="color: #2474ed"
                theme="filled"
              />
            </a-tooltip>
          </a-radio>
        </a-radio-group>
      </div>
      <div class="title">是否显示班级排名</div>
      <div class="radio">
        <a-radio-group v-model="params.publishSchoolIsDisplayClassRank">
          <a-radio :value="1"> 显示 </a-radio>
          <a-radio :value="0"> 不显示 </a-radio>
        </a-radio-group>
      </div>
    </template>
    <template v-if="mode === '2'">
      <div class="title">发布到学生的数据报告显示设置</div>
      <a-checkbox-group v-model="form.stats" style="margin-bottom: 18px">
        <a-row>
          <a-col :span="6">
            <a-checkbox value="1" :disabled="switchOne">
              <span>仅卷面分</span>
              <div class="img">
                <img src="@/assets/imgs/exam/list1.png" alt="" />
              </div>
            </a-checkbox>
          </a-col>
          <a-col :span="6">
            <a-checkbox value="2" :disabled="switchTwo">
              <span>卷面分及排名</span>
              <div class="img">
                <img
                  style="width: 300px; height: 230px; object-fit: cover"
                  src="@/assets/imgs/exam/list2-union.png"
                  alt=""
                />
              </div>
            </a-checkbox>
          </a-col>
          <a-col :span="6">
            <a-checkbox value="3">
              <span>知识点分析</span>
              <div class="img">
                <img src="@/assets/imgs/exam/list5.png" alt="" />
              </div>
            </a-checkbox>
          </a-col>
          <a-col :span="6">
            <a-checkbox value="4">
              <span>小题分</span>
              <div class="img">
                <img src="@/assets/imgs/exam/list4.png" alt="" />
              </div>
            </a-checkbox>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="6">
            <a-checkbox value="5">
              <span>等级分（等级设置）</span>
              <div class="img">
                <img src="@/assets/imgs/exam/list3.png" alt="" />
              </div>
            </a-checkbox>
          </a-col>
          <a-col :span="6">
            <a-checkbox value="6">
              <span>试卷夹</span>
              <div class="img">
                <img src="@/assets/imgs/exam/list6.png" alt="" />
              </div>
            </a-checkbox>
          </a-col>
          <a-col :span="6">
            <a-checkbox value="7">
              <span>学科雷达图</span>
              <div class="img">
                <img src="@/assets/imgs/exam/list7.png" alt="" />
              </div>
            </a-checkbox>
          </a-col>
          <a-col :span="6">
            <a-checkbox value="8">
              <span>答题卡</span>
              <div class="img">
                <img src="@/assets/imgs/exam/list8.png" alt="" />
              </div>
            </a-checkbox>
          </a-col>
        </a-row>
      </a-checkbox-group>
    </template>
    <a-button type="primary" @click="submit">应用</a-button>
  </div>
</template>

<script>
import { levelOptions } from "@/core/util/constdata";
import { getReportSetting, saveReportSetting } from "@/core/api/newOther/union";
export default {
  name: "ReportSetting",
  data() {
    const nowYear = new Date().getFullYear();
    const arr = new Array(nowYear - 2014).fill("");
    let yearData = arr.map((item, index) => {
      return { label: index + 2015, value: index + 2015 };
    });
    let yearList = yearData.reverse();
    return {
      mode: "1",
      search: {
        level: undefined,
        year: undefined,
      },
      form: {
        stats: [],
      },
      params: {
        publishSchoolIsDisplayStudentRank: 0,
        publishSchoolStudentRankListOrder: 1,
        publishSchoolIsDisplayAreaAverageScore: 0,
        publishSchoolIsDisplaySchoolRank: 1,
        publishSchoolIsDisplayClassRank: 1,
        publishStudentModule: 0,
      },
      yearList: yearList,
      levelOptions: levelOptions(),
      switchOne: false,
      switchTwo: false,
    };
  },
  watch: {
    form: {
      deep: true,
      handler() {
        if (this.mode == "2") {
          if (this.form.stats.includes("1")) {
            this.switchOne = false;
            this.switchTwo = true;
          } else if (this.form.stats.includes("2")) {
            this.switchOne = true;
            this.switchTwo = false;
          } else {
            this.switchOne = false;
            this.switchTwo = false;
          }
        }
      },
    },
  },
  created() {
    this.search.level = this.levelOptions[0].value;
    this.search.year = this.yearList[0].value;
    this.getData();
  },
  methods: {
    async submit() {
      this.params.publishStudentModule = this.form.stats.join(",");
      try {
        this.loading = true;
        await saveReportSetting({ ...this.params, ...this.search });
        this.loading = false;
        this.$message({
          type: "success",
          message: "提交成功",
          showClose: true,
        });
        this.getData();
      } catch {
        this.loading = false;
      }
    },
    filterChange() {
      if (["1", "2"].includes(this.search.level)) {
        this.params.publishSchoolIsDisplayStudentRank = 0;
        this.params.publishSchoolStudentRankListOrder = 2;
      }
      this.getData();
    },
    async getData() {
      try {
        this.loading = false;
        const res = await getReportSetting({ ...this.search });
        if (["1", "2"].includes(this.search.level)) {
          this.params.publishSchoolIsDisplayStudentRank = 0;
          this.params.publishSchoolStudentRankListOrder = 2;
        }
        if (res.data.data) {
          this.params = { ...res.data.data };
          this.form.stats = res.data.data
            ? res.data.data.publishStudentModule.split(",")
            : [];
        } else {
          this.params = {};
          this.form.stats = [];
        }
      } catch {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.reportSetting {
  .filter {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 18px 0;

    .filter-item {
      margin-right: 8px;
    }
  }

  .title {
    height: 36px;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 0 8px;
    line-height: 36px;
    margin-bottom: 18px;
    font-size: 14px;
    color: #0a1119;
  }

  .radio {
    margin-bottom: 18px;
  }

  .sub-title {
    color: #0a1119;
    font-size: 14px;
    margin-bottom: 18px;
  }
}

.ant-checkbox-group {
  width: 100%;
}

.tag {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 18px;
  margin-bottom: 18px;
  background-color: #f3f8ff;
  height: 36px;
  font-size: 14px;
  color: #3e4551;
  font-weight: 600;

  i {
    margin: 0 8px;
    color: rgb(36, 116, 237);
  }
}
</style>
