<template>
  <div v-loading="loading" class="sheetSetting">
    <div class="filter">
      适用学段学年：
      <div class="filter-item">
        学段：
        <a-select
          v-model="search.level"
          style="width: 120px"
          not-found-content="暂无数据"
          placeholder="全部学段"
          @change="filterChange"
        >
          <a-select-option
            v-for="(item, index) in levelOptions"
            :key="index"
            :value="item.value"
            >{{ item.label }}</a-select-option
          >
        </a-select>
      </div>
      <div class="filter-item">
        学年：
        <a-select
          v-model="search.year"
          style="width: 120px"
          not-found-content="暂无数据"
          placeholder="全部学年"
          @change="filterChange"
        >
          <a-select-option
            v-for="(item, index) in yearList"
            :key="index"
            :value="item.value"
            >{{ item.label }}</a-select-option
          >
        </a-select>
      </div>
    </div>
    <div class="tag">
      联考端默认参数设置
      <a-tooltip placement="top">
        <template slot="title">
          <span>在统计设置-统计参数可针对每场考试进行设置</span>
        </template>
        <a-icon type="exclamation-circle" theme="filled" />
      </a-tooltip>
    </div>
    <div class="title">临时榜成绩排名</div>
    <div class="radio">
      <a-radio-group
        v-model="params.isDisplayTempListRank"
        :disabled="['1', '2'].includes(search.level)"
        name="radioGroup"
      >
        <a-radio :value="1"> 显示 </a-radio>
        <a-radio :value="0"> 不显示 </a-radio>
      </a-radio-group>
    </div>
    <div class="title">成绩数据报表联考排名</div>
    <div class="radio">
      <a-radio-group
        v-model="params.isDisplayUnionRank"
        :disabled="['1', '2'].includes(search.level)"
      >
        <a-radio :value="1"> 显示 </a-radio>
        <a-radio :value="0">
          不显示
          <a-tooltip>
            <template slot="title">
              成绩统计表、导出的成绩包、学校成绩表、学生成绩条等完全不显示联考排名字段的任何数据
            </template>
            <a-icon
              type="question-circle"
              style="color: #2474ed"
              theme="filled"
            />
          </a-tooltip>
        </a-radio>
      </a-radio-group>
    </div>
    <div class="sub-title">成绩排序方式：</div>
    <div class="radio">
      <a-radio-group v-model="params.unionRankListOrder">
        <a-radio :value="1" :disabled="['1', '2'].includes(search.level)">
          成绩降序排序
        </a-radio>
        <a-radio :value="2"> 考号升序排序 </a-radio>
        <a-radio :value="4"> 随机排序 </a-radio>
        <a-radio :value="3"> 姓名拼音首字母排序 </a-radio>
      </a-radio-group>
    </div>
    <div class="tag">
      学校端默认参数设置
      <a-tooltip placement="top">
        <template slot="title">
          <span>在统计设置-统计参数可针对每场考试进行设置</span>
        </template>
        <a-icon type="exclamation-circle" theme="filled" />
      </a-tooltip>
    </div>
    <div class="title">成绩数据表校内排名</div>
    <div class="radio">
      <a-radio-group
        v-model="params.isDisplaySchoolRank"
        :disabled="['1', '2'].includes(search.level)"
      >
        <a-radio :value="1"> 显示 </a-radio>
        <a-radio :value="0">
          不显示
          <a-tooltip>
            <template slot="title">
              学校成绩包中的数据，不显示任何排名字段的数据，不论是联考排名、学校排名、班级排名、成绩条排名均不显示
            </template>
            <a-icon
              type="question-circle"
              style="color: #2474ed"
              theme="filled"
            />
          </a-tooltip>
        </a-radio>
      </a-radio-group>
    </div>
    <div class="sub-title">成绩排序方式：</div>
    <div class="radio">
      <a-radio-group v-model="params.schoolRankListOrder">
        <a-radio :value="1"> 成绩降序排序 </a-radio>
        <a-radio :value="2"> 考号升序排序 </a-radio>
        <a-radio :value="4"> 随机排序 </a-radio>
        <a-radio :value="3"> 姓名拼音首字母排序 </a-radio>
      </a-radio-group>
    </div>
    <a-button type="primary" @click="submit">应用</a-button>
  </div>
</template>

<script>
import { getformSetting, saveFormSetting } from "@/core/api/newOther/union";
import { levelOptions } from "@/core/util/constdata";
export default {
  name: "SheetSetting",
  data() {
    const nowYear = new Date().getFullYear();
    const arr = new Array(nowYear - 2014).fill("");
    let yearData = arr.map((item, index) => {
      return { label: index + 2015, value: index + 2015 };
    });
    let yearList = yearData.reverse();
    return {
      search: {
        level: undefined,
        year: undefined,
      },
      loading: false,
      params: {
        isDisplaySchoolRank: 0,
        isDisplayTempListRank: 0,
        isDisplayUnionRank: 0,
        schoolRankListOrder: 1,
        unionRankListOrder: 1,
      },
      switchOne: false,
      switchTwo: false,
      yearList: yearList,
      levelOptions: levelOptions(),
    };
  },
  watch: {
    form: {
      deep: true,
      handler() {
        if (this.mode === "2") {
          if (this.form.stats.includes("1")) {
            this.switchOne = false;
            this.switchTwo = true;
          } else if (this.form.stats.includes("2")) {
            this.switchOne = true;
            this.switchTwo = false;
          } else {
            this.switchOne = false;
            this.switchTwo = false;
          }
        }
      },
    },
  },
  created() {
    this.search.level = this.levelOptions[0].value;
    this.search.year = this.yearList[0].value;
    this.getData();
  },
  methods: {
    async submit() {
      this.loading = true;
      try {
        await saveFormSetting({ ...this.params, ...this.search });
        this.getData();
        this.$message({
          type: "success",
          message: "应用成功",
          showClose: true,
        });
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },
    filterChange() {
      if (["1", "2"].includes(this.search.level)) {
        this.params.isDisplayTempListRank = 0;
        this.params.isDisplayUnionRank = 0;
        this.params.isDisplaySchoolRank = 0;
        this.params.unionRankListOrder = 2;
      }
      this.getData();
    },
    async getData() {
      this.loading = true;
      try {
        const res = await getformSetting({ ...this.search });
        if (res.data.data) {
          this.params = res.data.data;
        } else {
          this.params = {
            isDisplaySchoolRank: 0,
            isDisplayTempListRank: 0,
            isDisplayUnionRank: 0,
            schoolRankListOrder: 1,
            unionRankListOrder: 1,
          };
        }
        if (["1", "2"].includes(this.search.level)) {
          this.params.isDisplayTempListRank = 0;
          this.params.isDisplayUnionRank = 0;
          this.params.isDisplaySchoolRank = 0;
          this.params.unionRankListOrder = 2;
        }
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.sheetSetting {
  .filter {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 18px;
    .filter-item {
      display: flex;
      align-items: center;
      margin-right: 18px;
    }
  }
  .title {
    height: 36px;
    // background: #f3f8ff;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 0 8px;
    line-height: 36px;
    margin-bottom: 18px;
    font-size: 14px;
    color: #0a1119;
  }
  .radio {
    margin-bottom: 18px;
  }
  .sub-title {
    color: #0a1119;
    font-size: 14px;
    margin-bottom: 18px;
  }
}
.tag {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 18px;
  background-color: #f3f8ff;
  height: 36px;
  font-size: 14px;
  padding: 0 18px;
  color: #3e4551;
  font-weight: 600;
  i {
    margin: 0 8px;
    color: rgb(36, 116, 237);
  }
}
</style>
