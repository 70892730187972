<template>
  <div v-loading="loading" class="subjectSetting">
    <div class="tip">
      <a-icon
        type="warning"
        theme="filled"
        style="color: #f57574; margin-right: 8px"
      />
      注意：若所需科目不在列表，请及时联系系统管理员添加；
    </div>
    <div class="filter">
      适用学段学年：
      <div class="filter-item">
        学段：
        <a-select
          v-model="search.level"
          style="width: 120px"
          not-found-content="暂无数据"
          placeholder="全部学段"
          @change="filterChange"
        >
          <a-select-option
            v-for="(item, index) in levelOptions"
            :key="index"
            :value="item.value"
            >{{ item.label }}</a-select-option
          >
        </a-select>
      </div>
      <a-button type="primary" @click="submit">应用</a-button>
    </div>
    <div class="title">科目设置</div>
    <a-checkbox-group
      v-model="params.subjects"
      style="margin-bottom: 18px"
      @change="() => $forceUpdate()"
    >
      <a-row>
        <a-col
          v-for="(item, index) in subjectList"
          :key="index"
          :span="3"
          :offset="1"
        >
          <a-checkbox :value="item.id">
            <a-tooltip>
              <template slot="title"> {{ item.name }} </template>
              <span> {{ item.name }} </span>
            </a-tooltip>
          </a-checkbox></a-col
        >
      </a-row>
    </a-checkbox-group>
  </div>
</template>

<script>
import { levelOptions } from "@/core/util/constdata";
import {
  getSubjectSetting,
  getAllSubject,
  saveSubjectSetting,
} from "@/core/api/newOther/union";
import { schoolSubject } from "@/core/api/newOther/index";
export default {
  name: "SubjectSetting",
  data() {
    const nowYear = new Date().getFullYear();
    const arr = new Array(nowYear - 2014).fill("");
    let yearData = arr.map((item, index) => {
      return { label: index + 2015, value: index + 2015 };
    });
    let yearList = yearData.reverse();
    return {
      subjects: [],
      levelOptions: levelOptions(),
      yearList: yearList,
      loading: false,
      search: {
        // year: undefined,
        level: undefined,
      },
      params: {
        subjects: [],
      },

      subjectList: [],
    };
  },
  mounted() {
    this.search.level = this.levelOptions[0].value;
    // this.search.year = this.yearList[0].value;

    this.getAllSubject();
  },
  methods: {
    async submit() {
      try {
        this.loading = true;
        await saveSubjectSetting({
          ...this.search,
          subjectIds: this.params.subjects.join(","),
        });
        this.$message({
          type: "success",
          message: "提交成功",
          showClose: true,
        });
        this.getData();
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },
    filterChange() {
      this.getData();
    },
    async getAllSubject() {
      const res = await getAllSubject();
      this.subjectList = res.data.data;
      this.getData();
    },
    async upDataSchoolList() {
      const res = await schoolSubject();
      let data = {};
      data = JSON.parse(localStorage.getItem("jzjx-schoolSubjectList"));
      if (data) {
        data.content = res.data.data;
      } else {
        data = {
          content: res.data.data,
        };
      }
      data.content = data.content.map((item) => {
        (item.value = item.subjectId), (item.label = item.subjectName);
        return item;
      });
      // console.log(data);
      localStorage.setItem("jzjx-schoolSubjectList", JSON.stringify(data));
    },
    async getData() {
      try {
        this.loading = true;
        const res = await getSubjectSetting({ ...this.search });
        this.params.subjects = res.data.data.map((item) => item.subjectId);
        this.loading = false;
        this.upDataSchoolList();
      } catch {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.subjectSetting {
  .tip {
    height: 40px;
    background: #fff3f3;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 0 18px;
    margin-bottom: 18px;
    color: #292626;
    font-size: 14px;
    line-height: 40px;
  }
  .filter {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 18px 0;
    .filter-item {
      margin-right: 8px;
    }
  }
  .title {
    height: 36px;
    background: #f3f8ff;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 0 8px;
    line-height: 36px;
    margin-bottom: 18px;
    font-size: 14px;
    color: #0a1119;
  }
  .ant-checkbox-group {
    width: 100%;
  }
}
.ant-checkbox-wrapper {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  margin-bottom: 24px;
}
</style>
